<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <form role="form" @submit.prevent="handleReportForm">
      <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8 order-xl-1">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Generowanie raportu</h3>
              </div>
            </div>

            <div
              class="alert"
              :class="formAlert.type ? `alert-${formAlert.type}` : '' "
              v-if="formAlert.type"
            >
              {{ formAlert.message }}
            </div>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-md-12">
                  <base-input label="Rodzaj" placeholder="">
                    <el-select v-model="reportForm.type" filterable placeholder="Wybierz rodzaj raportu">
                      <el-option key="bookkeeking" label="Raport dla księgowej" value="bookkeeping"></el-option>
                      <el-option key="default" label="Domyślny" value="default"></el-option>
                      <el-option key="sales" label="Sprzedaż E-commerce" value="sales"></el-option>
                      <el-option key="categories" label="Sprzedaż - kategorie produktowe" value="categories"></el-option>
                      <el-option key="lines" label="Sprzedaż - linie produktowe" value="lines"></el-option>
                      <el-option key="nowaste" label="Sprzedaż NO WASTE" value="nowaste"></el-option>
                      <el-option key="products" label="Ranking produktów" value="products"></el-option>
                      <el-option key="coupons" label="Kody promocyjne" value="coupons"></el-option>
                      <el-option key="times" label="Promocje czasowe" value="times"></el-option>
                      <el-option key="customers" label="Ranking Klientów" value="customers"></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <base-input type="date" label="Data od" placeholder="" v-model="reportForm.dateFrom"></base-input>
                </div>
                <div class="col-md-6">
                  <base-input type="date" label="Data do" placeholder="" v-model="reportForm.dateTo"></base-input>
                </div>
              </div>
            </div>

            <div class="pl-lg-4">
              <div class="text-right">
                <base-button type="primary" native-type="submit">Generuj</base-button>
              </div>
            </div>
          </card>
        </div>
        <div class="col-xl-4 order-xl-2">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Opis raportów</h3>
              </div>
            </div>
            <div class="pl-lg-4">
              <div class="row">
                Obecnie dostępne są następujące typy raportów:
                <ul>
                  <li>Raport dla księgowej</li>
                  <li>Raport z kodami rabatowymi</li>
                  <li>Sprzedaż E-commerce</li>
                  <li>Sprzedaż - kategorie produktowe</li>
                  <li>Sprzedaż - linie produktowe</li>
                  <li>Sprzedaż NO WASTE</li>
                  <li>Ranking produktów</li>
                  <li>Kody promocyjne</li>
                  <li>Promocje czasowe</li>
                  <li>Ranking Klientów</li>
                </ul>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import { mapState, mapActions } from 'vuex'
import {Option, Select} from "element-ui";

export default {
  components: {
    BreadcrumbHeader,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      reportForm: {
        type: '',
        dateFrom: '',
        dateTo: '',
      },
      formAlert: {
        message: '',
        type: null
      },
      formError: {
        message: '',
        hasErrors: false
      }
    };
  },
  computed: {
    ...mapState('Auth', ['token']),
    breadcrumb() {
      return [
        {title: 'Raporty'}
      ];
    },
  },
  methods: {
    ...mapActions('ReportViewModule', ['fetchReport']),
    async handleReportForm() {
      const { dateFrom, dateTo, type } = this.reportForm

      if ( dateFrom && dateTo && type ) {
        try {
          this.formAlert.message = ''
          this.formAlert.type = null

          await this.fetchReport({
            token: this.token,
            dateTo,
            dateFrom,
            type
          })

          this.handleAlert('success', 'Raport został wygenerowany poprawnie')
        } catch (e) {
          this.handleAlert('danger', e.message)
        }

      }
      if ( [dateFrom, dateTo, type].includes(null) || [dateFrom, dateTo, type].includes(undefined) || [dateFrom, dateTo, type].includes('') ) {
        this.handleAlert('danger', 'Formularz zawiera błędy')
      }
    },
    handleAlert(type, message) {
      this.formAlert.type = type
      this.formAlert.message = message
    }
  }
};
</script>
